
.jetton-info-top-container {
    display: flex;
    gap: 16px;
    margin-bottom: 18px;
}

.jetton-info {
    display: flex;
    padding: 0;
    flex-direction: row!important;

    &--left {
        flex-grow: 1;
    }

    &--right {
        margin-top: 0!important;
        width: 380px;
    }
}

.jetton-chart-empty {
    width: calc(100% - 20px);
    height: calc(100% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    &__mobile {
        height: 230px;
        border: 0;
    }
}
.jetton-chart-empty-content {
    &__title {
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }
    &__description {
        text-align: center;
        color: var(--body-muted-text-color);
        margin-bottom: 35px;
    }
}
.jetton-hide-expander {
    color: var(--blue-bright);
    cursor: pointer;
    font-weight: 500;
    margin-bottom: -10px;
    margin-top: -10px;
    z-index: 900;
    position: absolute;
    bottom: 11px;
    right: 18px;
    padding: 2px 5px;
    background: linear-gradient(to left,var(--card-background) 20%,transparent);
    display: none;
}

.jetton-description-hide .jetton-hide-expander {
    display: block;
}

.jetton-hide-expander span {
    position: relative;
    z-index: 10;
}

.jetton-hide-expander:hover {
    text-decoration: underline;
}

.jetton-description-container {
    position: relative;
}

.jetton-description-hide .jetton-description {
    max-height: 21px;
    overflow: hidden;
    position: relative;
}

.jetton-hide-expander:after {
    background: linear-gradient(to left,var(--card-background) 70%,transparent);
    bottom: 0;
    content: "";
    height: 100%;
    width: 160px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.jetton-info-image {
    border-radius: 50%;
    margin: 0 14px 0 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    align-self: flex-start;
}

.jetton-meta {
    width: 350px;
    border-right: 1px solid var(--card-border-color);
    // padding: 16px;
    box-sizing: border-box;
}

.jetton-chart {
    flex-grow: 1;
    padding: 18px 2px 0 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        margin-bottom: 12px;
        padding-right: 16px;
        display: flex;
        justify-content: right;
        align-items: center;

        &--title {
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.jetton-meta-header {
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;

    p {
        margin: 0;
    }

    &__name {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px!important;
    }

    &__symbol {
        font-size: 16px;
        color: var(--body-muted-text-color);
    }

    &__icon-verified {
        margin-left: 6px;
        color: var(--app-icon-verified-color);
        transform: translateY(2px);
        width: 14px;
        height: 14px;
    }
}

.jetton-meta-price {
    display: flex;
    align-items: center;
    padding: 0 16px;

    &__value {
        font-size: 30px;
        font-weight: 500;
        margin-right: 15px;
    }
}

.jetton-description {
    color: var(--body-muted-text-color);
    line-height: 20px;
    font-size: 14px;
    margin: 16px 16px 16px 16px;
}

.jetton-switcher {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 3px;
    background: var(--chart-interval-selector-background);
    color: var(--chart-interval-selector-color);
    text-transform: none;
    margin-left: 16px;
    margin-right: 16px; margin-bottom: 7px;
}

.jetton-switcher-button {
    width: 50%;
    text-align: center;
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.jetton-switcher .active {
    background: var(--chart-interval-selector-item-background);
    color: var(--chart-interval-selector-item-active-color);
}

.jetton-meta-header-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
    padding-top: 16px;
}

@media screen and (max-width: 991px) {
    // .jetton-description-hide .jetton-hide-expander {
    //     display: none;
    // }

    .jetton-description-hide .jetton-description {
        max-height: 20px;
    }

    .jetton-meta {
        border: none;
        width: 100%;
    }

    .jetton-info {
        flex-direction: column!important;
    }

    .jetton-chart {
        padding: 0;
        margin-bottom: 10px;
    }

    .jetton-swap {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        max-width: none;
    }
}

@media screen and (max-width: 991px) {
    .jetton-meta-header-items {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }
}

.dyor-logo-jettons-mobile{
    color: inherit;
    font-size: 13px;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 6px 0;
    margin: 8px 16px 8px 0;
    text-transform: none;
    font-weight: normal;
    opacity: .65;
    transition: .12s all ease;

    &:hover {
        opacity: 1;
        color: var(--blue-bright);
        text-decoration: none;
    }
    &__icon {
        fill: currentColor;
        width: 16px;
        height: 16px;
        margin-left: 5px;
    }
}
