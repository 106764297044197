
.nft-item-container {
    display: flex;
    margin-top: 32px;
    flex-direction: row;
    margin-bottom: 32px;
    &__info {
        margin-top: auto;
        flex-grow: 1;
        flex-basis: 100%;
    }
}

.nft-item-transactions-container {
    margin-top: 32px;
}

.nft-item-image-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 130px;
    padding-bottom: 130px;
    width: 100%;
    height: 100%;
    &__icon {
        width: 36px;
        cursor: pointer;
        fill: currentColor;
        margin-bottom: 24px;
        margin-top: -16px;
    }
    &__title {
        padding: 0;
        text-align: center;
        margin: 0 0 8px;
        font-weight: 500;
        font-size: 18px;
    }
    &__text {
        opacity: .5;
        cursor: pointer;
        font-size: 0.85em;
        &--mobile {
            display: none;
        }
    }

    @media all and (max-width: 480px) {
        &__icon {
            margin-bottom: 16px;
        }
        &__title {
            font-size: 16px;
        }
        &__text {
            &--mobile {
                display: block;
            }
            &--desktop {
                display: none;
            }
        }
    }
}

.nft-image-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 32px;
    background-size: cover;
    background-position: center;
    background-blend-mode: soft-light;
    border: 1px solid transparent;
    width: 100%;
    max-width: 420px;
    min-height: 260px;
    position: relative;
    &__image {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 16px!important;
        display: block;
        object-fit: contain;
        //margin: auto;

        &.skeleton {
            border-radius: 16px;
            background-image: none;
            box-shadow: none;
            aspect-ratio: 1 / 1;
            width: 100%;
        }
    }
}

.nft-item-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 150px;
    &__title {
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
    &__description {
        font-size: 17px;
        margin-bottom: 18px;
        line-height: 1.4;
    }
}

.nft-card-collection {
    display: flex;
    align-items: center;
    &__thumb {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin: -8px 6px -8px 0;
    }
}

.card-row-inline-pager {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin: -6px;
        cursor: pointer;
        &[disabled] {
            color: #777;
            pointer-events: none;
            opacity: .5;
        }
        &:hover .card-row-inline-pager__arrow__svg {
            opacity: 1;
        }
        &__svg {
            cursor: pointer;
            fill: currentColor;
            width: 12px;
            height: 12px;
            padding: 4px;
            margin: -4px 2px;
            background: var(--card-row-separator);
            opacity: .6;
            transition: .08s opacity ease-out;
            border-radius: 4px;
        }
    }
}

@media all and (max-width: 800px) {
    .nft-item-container {
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 18px;
    }

    .nft-image-container {
        max-width: unset;
        overflow: hidden;
        border-radius: 16px;
        &__image {
            height: 100%;
            border-radius: 16px;
        }
    }

    .nft-item-transactions-container {
        margin-top: 18px;
    }

    .nft-item-header {
        box-sizing: border-box;
        padding: 0 16px;
        &__title {
            margin-top: 18px;
            margin-bottom: 6px;
            font-size: 26px;
        }
        &__description {
            font-size: 16px;
            line-height: 1.4;
        }
    }

    .nft-card-collection__thumb {
        width: 20px;
        height: 20px;
    }

    .card-row-inline-pager {
        &__arrow {
            &__svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
}
